import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm( {clientSecret, priceObj, product, buyCredits, authEmail } ) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //console.log(priceObj);
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          const timeElapsed = Date.now();
          const today = new Date(timeElapsed);
          setMessage("Your most recent purchase was successfully made on " + today.toLocaleDateString() + ".");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
      // Change url to https://danstestprep.com/ai
        return_url: "https://danstestprep.com/ai",
        //receipt_email: email,
        receipt_email: authEmail,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <>
    <form id="payment-form" onSubmit={handleSubmit}>
      {product && (
        <>
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <p>Price: ${(priceObj.unit_amount/100).toFixed(2)} ({priceObj.currency}) </p>
        </>
      )}
      <input
        id="email"
        type="text"
        value={authEmail}
        //onChange={(e) => setEmail(e.target.value)}
        placeholder="Email address"
        readOnly
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button className="paymentButton" disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    <br/>
    <form id="cancelButton">
    <button className="paymentButton" onClick={buyCredits}> Cancel Purchase </button>
    </form>
    </>
  );
}
