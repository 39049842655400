const errorMessages = {
    'auth/email-already-in-use': 'The email address is already in use.',
    'auth/user-not-found': 'Email or password is incorrect.',
    'auth/wrong-password': 'Email or password is incorrect.',
    'auth/invalid-email': 'The email address is not valid. Please enter a valid email and try again.',
    'auth/weak-password': 'Password should be at least 6 characters.',
    'auth/operation-not-allowed': 'Email/password accounts are not enabled.',
    'default': 'An error occurred. Please try again later.'
}

export default errorMessages;