import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import "./App.css";
import { auth } from "./firebase-config";
import Chatbot from './Chatbot';
import Verify from './Verify';
import errorMessages from './errorMessages'; // remove get UID
import { dbNewUser, dbCheckCredits, dbGetUID } from './firebase-config';
import { Payment } from './Payment';

function App() {

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorRegister, setErrorRegister] = useState(null);
  const [errorLogin, setErrorLogin] = useState(null);
  const [user, setUser] = useState({});
  const defaultCredits = 100; // Change this when out of beta
  const [pay, setPay] = useState(false);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (user && user.uid) {
      dbCheckCredits(user.uid)
        .then(currentCredits => setCredits(currentCredits));
    }
  }, [user, credits, pay]);

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      )
      .then(() => {
        // add user to firestore db
        dbNewUser(
          auth.currentUser.uid,
          displayName,
          registerEmail,
          defaultCredits,
          Date.now())
      })

      await updateProfile(auth.currentUser, {
        displayName: displayName
      }).then(() => {
        console.log("Display name updated");
      }).catch((error) => {
        console.log("Error in updating display name")
      });
      if(!auth.currentUser.emailVerified){
        await sendEmailVerification(auth.currentUser)
          .then(() => {
            console.log("Verification email sent")
          }).catch((error) => {
            console.log("Error sending verification email")
          });
      }
      setErrorRegister(null);
    } catch (error) {
      setErrorRegister(errorMessages[error.code] || errorMessages.default);
      console.log(error.message)
    }
  };
  
  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      /*
      if(!auth.currentUser.emailVerified){
        await sendEmailVerification(auth.currentUser)
          .then(() => {
            console.log("Verification email sent")
          }).catch((error) => {
            console.log("Error sending verification email")
          });
      }*/
      setErrorLogin(null);
    } catch (error) {
      setErrorLogin(errorMessages[error.code] || errorMessages.default);
      console.log(error.message)
    }
  };

  const logout = async () => {
    await signOut(auth);
    setErrorRegister(null);
    setErrorLogin(null);
  };

  const buyCredits = () => {
    //await setPay(true);
    setPay(prevPay => !prevPay);
    //console.log(pay);
  }

return (
  <div className="App">
    <div className="title">
      <h1>ChatSAT Reading Assistant</h1>
    </div>
    {user && !user.emailVerified ? (
      <>
        <div className="header">
          <div className="current-user">
            <p><strong>User Logged In:</strong> {user?.email}</p>
          </div>
          <div className="sign-out">
            <button onClick={logout}> Sign Out </button>
          </div>
        </div>
        <Verify email={user.email} /> 
      </>
      ) : null}
    {user && user.emailVerified ? (
      <>
        <div className="header">
          <div className="current-user">
            <p className="headerText"><strong>User Logged In:</strong> {user?.email}
            <br/><br/><strong>Credits Remaining:</strong> {credits}</p>
          </div>
          <div className="sign-out">
            <button className="headerButton" onClick={logout}> Sign Out </button>
            <button className="headerButton" onClick={buyCredits}> Buy Credits </button>
          </div>
        </div>
        {pay ? <Payment buyCredits={buyCredits} authEmail={auth.currentUser.email}/> : <Chatbot setCredits={setCredits}/>}
      </>
      ) : null}
    {!user ? (
      <>
        <div className="login-register-container">
          <div className="login-register-column">
            <h3> Register User </h3>
            <input
              placeholder="Name..."
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
            />
            <br/>
            <input
              type="email"
              placeholder="Email..."
              onChange={(event) => {
                setRegisterEmail(event.target.value);
              }}
            />
            <br/>
            <input
              type="password"
              placeholder="Password..."
              onChange={(event) => {
                setRegisterPassword(event.target.value);
              }}
            />
            <br/>
            <button onClick={register}> Create User</button>
            {errorRegister && <p className="errorRegister">{errorRegister}</p>}
          </div>
          <div className="login-register-column">
            <h3> Login </h3>
            <input
              type="email"
              placeholder="Email..."
              onChange={(event) => {
                setLoginEmail(event.target.value);
              }}
            />
            <br/>
            <input
              type="password"
              placeholder="Password..."
              onChange={(event) => {
                setLoginPassword(event.target.value);
              }}
            />
            <br/>
            <button onClick={login}> Login</button>
            {errorLogin && <p className="errorLogin">{errorLogin}</p>}
          </div>
        </div>
          <div className="desc">
            <br />
            <br />
            <h3>About ChatSAT</h3>
            <p>ChatSAT is an AI Assistant designed to help you
               study for the reading section of the SAT. This is
               often the hardest section to study for, especially
               without answer explanations for many tests. Look
               no further, cause ChatSAT's got you covered! You
               can ask questions about any SAT passage and question
               pair and ChatSAT will give you the answer.
            </p>
            <br />
            <p>To start using, create a free account or log into your
               existing Dan's Test Prep account using your email.
            </p>
          </div>
      </>
    ) : null}
  </div>
);
}

export default App;
