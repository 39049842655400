// Contains code for firebase auth and firestore database

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, updateDoc, Timestamp} from 'firebase/firestore'
import { collection, where, getDocs } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCBk3Quf7wj2O12JEQ50iKPMupfxsOwgIE",
  authDomain: "dans-test-prep.firebaseapp.com",
  projectId: "dans-test-prep",
  storageBucket: "dans-test-prep.appspot.com",
  messagingSenderId: "1061662876324",
  appId: "1:1061662876324:web:2fd55e1c6b07716b773c31",
  measurementId: "G-1WPREC6K20"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export async function dbNewUser(uid, name, email, credits, lastlogin) {
  try {
    const docData = {
      name: name,
      email: email,
      credits: credits,
      lastlogin: Timestamp.now(),
    }
    await setDoc(doc(db, "users", uid), docData);
    console.log("Document written");
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function dbCheckCredits(uid) {
  const userRef = doc(db, "users", uid);
  const userData = await getDoc(userRef);
  const currentCredits = userData.data().credits;
  return currentCredits;
}


export async function dbUpdateCredits(uid, num) {
  const userRef = doc(db, "users", uid);
  const userData = await getDoc(userRef);
  const currentCredits = userData.data().credits;
  try {
    await updateDoc(userRef, {
      credits: currentCredits + num,
    });
  } catch (e) {
    console.error("Error updating credits: ", e);
  }
}

export async function dbGetUID(email) {
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(usersRef, where("email", "==", email));
    if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id
    } else {
        return null;
    }
}

export const auth = getAuth(app);