import './App.css';

function Verify({ email }: { email: string }) {

  return (
    <div className="desc">
      <h2>Account Verification Needed</h2>
      <p>A verification email has been sent to <strong>{email}</strong>.
      <br/>
      <br/>
         Please follow the instructions in the email to verify your account.
      </p>
      <br/>
      <p>Once you have verified your account, please click <a href=".">here</a> or 
         refresh the page to continue.
      </p>
    </div>
  );
}

export default Verify;
