const questionList = [
        { name: "Custom Question", question: ""},
        { name: "Question 1", question: "Question 1" },
        { name: "Question 2", question: "Question 2" },
        { name: "Question 3", question: "Question 3" },
        { name: "Question 4", question: "Question 4" },
        { name: "Question 5", question: "Question 5" },
        { name: "Question 6", question: "Question 6" },
        { name: "Question 7", question: "Question 7" },
        { name: "Question 8", question: "Question 8" },
        { name: "Question 9", question: "Question 9" },
        { name: "Question 10", question: "Question 10" },
        { name: "Question 11", question: "Question 11" },
        { name: "Question 12", question: "Question 12" },
        { name: "Question 13", question: "Question 13" },
        { name: "Question 14", question: "Question 14" },
        { name: "Question 15", question: "Question 15" },
        { name: "Question 16", question: "Question 16" },
        { name: "Question 17", question: "Question 17" },
        { name: "Question 18", question: "Question 18" },
        { name: "Question 19", question: "Question 19" },
        { name: "Question 20", question: "Question 20" },
        { name: "Question 21", question: "Question 21" },
        { name: "Question 22", question: "Question 22" },
        { name: "Question 23", question: "Question 23" },
        { name: "Question 24", question: "Question 24" },
        { name: "Question 25", question: "Question 25" },
        { name: "Question 26", question: "Question 26" },
        { name: "Question 27", question: "Question 27" },
        { name: "Question 28", question: "Question 28" },
        { name: "Question 29", question: "Question 29" },
        { name: "Question 30", question: "Question 30" },
        { name: "Question 31", question: "Question 31" },
        { name: "Question 32", question: "Question 32" },
        { name: "Question 33", question: "Question 33" },
        { name: "Question 34", question: "Question 34" },
        { name: "Question 35", question: "Question 35" },
        { name: "Question 36", question: "Question 36" },
        { name: "Question 37", question: "Question 37" },
        { name: "Question 38", question: "Question 38" },
        { name: "Question 39", question: "Question 39" },
        { name: "Question 40", question: "Question 40" },
        { name: "Question 41", question: "Question 41" },
        { name: "Question 42", question: "Question 42" },
        { name: "Question 43", question: "Question 43" },
        { name: "Question 44", question: "Question 44" },
        { name: "Question 45", question: "Question 45" },
        { name: "Question 46", question: "Question 46" },
        { name: "Question 47", question: "Question 47" },
        { name: "Question 48", question: "Question 48" },
        { name: "Question 49", question: "Question 49" },
        { name: "Question 50", question: "Question 50" },
        { name: "Question 51", question: "Question 51" },
        { name: "Question 52", question: "Question 52" },
]

export default questionList;