import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./Payment.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51JYEqrKHXKdQ2njs7THLtNeaOogjGBxXOVdEUJCOdVdg5CKUe94Izo6d19zzt7FnqEWfU1m8oE1LI1H0nYKfudIc00zS8J8Md0");

export function Payment( {buyCredits, authEmail} ) {
  const [clientSecret, setClientSecret] = useState("");
  const [priceObj, setPriceObj] = useState(null);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // Change url to https://danstestprep:3001/create-payment-intent
    fetch("https://danstestprep.com:3001/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "price_1MS0XtKHXKdQ2njs2CwPLnUJ" }] }),
      // price_1MS0XtKHXKdQ2njs2CwPLnUJ is the 100 credits for $20
    })
      .then((res) => res.json())
      .then((data) => {
         setClientSecret(data.clientSecret);
         setPriceObj(data.priceObj);
         setProduct(data.product);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#ee5091',
      colorBackground: '#f7f7f7',
      colorText: '#2f303d',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="TopContainer">
      <div className="Payment">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} priceObj={priceObj} product={product} buyCredits={buyCredits} authEmail={authEmail}/>
          </Elements>
        )}
      </div>
      <div className="desc">
        <br/>
        <h3>About ChatSAT Pricing:</h3>
        <p>ChatSAT is <strong>10x cheaper</strong> than
           getting a tutor. Assuming an average price
           of $60/hr and 2 minutes to go through each
           question, a tutor would take <em>over three hours</em> and
           cost a whopping <em>$200</em> to go through a full reading section.
           ChatSAT does it in only a fraction of the cost. Better yet,
           you can take it at your own pace, without any time pressure.
           <br/><br/>
        </p>
        <p>Your purchase helps support the development
           and upkeep of ChatSAT. With your purchase,
           you'll receive credits to use on any available
           tests and questions in the question bank, as well as
           any custom question you choose to ask. If you need any support,
           please don't hesitate to contact me
           at <em>dan@danstestprep.com</em>.
        </p>
      </div>
    </div>
  );
}

export default Payment;
