import React, { useState, useEffect } from 'react';
import './Chatbot.css';
import { auth } from "./firebase-config";
import { dbCheckCredits, dbUpdateCredits } from './firebase-config.js'
//import 'react-toastify/dist/ReactToastify.min.css';
//import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import 'react-toastify/dist/ReactToastify.min.css';
import AutoFillPassage from "./AutoFillPassage";
import AutoFillQuestion from "./AutoFillQuestion";

let currentTime, lastRequestTime;

const parseLinesPassage = (value) => value.replace(/(\\n)/g, "\n    ");
const parseLinesQuestion = (value) => value.replace(/(\\n)/g, "\n");
const parseLinesResponse = (value) => value.replace(/(\\n)/g, "\n");

function Chatbot({setCredits}) {
  const [passage, setPassage] = useState('');
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');

  // These variables for cached completions
  const [testName, setTestName] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [cachedResponse, setCachedResponse] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

  useEffect(() => {
    if (document.getElementById("selectPassage").value !== "Custom Passage" &&
        document.getElementById("selectQuestion").value !== "Custom Question") {
      getPassageQuestion({testName}, {questionName});
    }
  },[testName, questionName]);

  const handleQuestionChange = (event) => {
    setQuestionName(event.target.value);
    if (document.getElementById("selectPassage").value !== "Custom Passage" &&
        document.getElementById("selectQuestion").value !== "Custom Question") {
      setTestName(document.getElementById("selectPassage").value);
      setQuestionName(document.getElementById("selectQuestion").value);
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  };

  const handlePassageChange = (event) => {
    setTestName(event.target.value);
    if (document.getElementById("selectPassage").value !== "Custom Passage" &&
        document.getElementById("selectQuestion").value !== "Custom Question") {
      setTestName(document.getElementById("selectPassage").value);
      setQuestionName(document.getElementById("selectQuestion").value);
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    currentTime = Date.now();
    //console.log(currentTime);
    if (currentTime - lastRequestTime < 5000) {
    toast.info("Too many requests, please slow down", {
      position: "top-center",
      autoClose: 4000,
    });
      return;
    }
    lastRequestTime = currentTime;
    //console.log(lastRequestTime);

    if (document.getElementById("selectPassage").value === "Custom Passage" ||
        document.getElementById("selectQuestion").value === "Custom Question") {
      setResponse("Reading the passage. . .");
      dbCheckCredits(auth.currentUser.uid)
        .then(result => {
          if (result <= 0) {
            setResponse("Error: You have 0 credits remaining. Please click the \"Buy Credits\" button to recharge.");
            throw new Error("No credits");
          } else { // Only when test != default and question != default do we use openAI
            setResponse("Reading the passage. . .\nObtaining answer. . .");
            fetch('https://danstestprep.com:3001/completion', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ passage, question }),
            }) 
            .then((res) => {
                if (!res.ok) {
                  throw Error(res.statusText);
                } else {
                  // costs 1 credit per successful query
                  dbUpdateCredits(auth.currentUser.uid, -1)
                  .then(() => {
                    dbCheckCredits(auth.currentUser.uid)
                    .then(result => {
                      setCredits(result - 1);
                    });
                  });
                }
                return res.json()
            })
            .then((data) => setResponse(data.message))
            .catch(error => {
              if (error.message === "Too Many Requests") {
                setResponse("Too many requests, please try again later.");
              } else {
                setResponse("ERROR: ChatSAT is currently down.\nPlease try again later.");
              }
            });
          }
        });
      }
      else { // Cached responses
      dbCheckCredits(auth.currentUser.uid)
        .then(result => {
          if (result <= 0) {
            setResponse("Error: You have 0 credits remaining. Please click the \"Buy Credits\" button to recharge.");
            throw new Error("No credits");
          } else {
            if (response === cachedResponse) {
              toast.info("You have already obtained the answer for this question!", {
                    position: "top-center",
                    autoClose: 4000,
              });
            } else {
              setResponse("Reading the passage. . .");
              setTimeout(() => {
                setResponse("Reading the passage. . .\nObtaining answer. . .");
              }, 500);
              setTimeout(() => {
                setResponse(cachedResponse);
              }, 1000);
              // costs 1 credit per successful query
              if (cachedResponse !== "Error: Could not obtain answer") {
                dbUpdateCredits(auth.currentUser.uid, -1)
                .then(() => {
                   dbCheckCredits(auth.currentUser.uid)
                   .then(result => {
                     setCredits(result - 1);
                   });
                });
              }
            }
          }
        });
      }

  };

  const getPassageQuestion = async (testName, questionName) => {
      try {
          const response = await fetch(`https://danstestprep.com:3001/completion-cached`, {
              method: "POST",
              body: JSON.stringify( { testName: testName.testName, questionName: questionName.questionName }),
              headers: { "Content-Type": "application/json" }
          });
          const data = await response.json();
          setPassage(data.passage);
          setQuestion(data.question);
          setCachedResponse(data.response);
      } catch (error) {
          console.error(error);
          setPassage("Error setting passage");
          setQuestion("Error setting question");
          setCachedResponse("Error: Could not obtain answer");
          setResponse(cachedResponse);
      }
  };

  return (
    <div className="Chatbot">
      <div>
        <ToastContainer />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="textarea-container">
          <div className="textarea-column">
            <p><strong>Passage</strong></p>
            <AutoFillPassage passage={passage} handlePassageChange={handlePassageChange} />
            <textarea
              className={isReadOnly ? "textarea textarea-passage response-textarea" : "textarea textarea-passage"}
              id="passage"
              value={parseLinesPassage(passage)}
              placeholder="This passage is from Lydia Minatoya, The Strangeness of Beauty. ©1999 by Lydia Minatoya. The setting is Japan in 1920. Chie and her daughter Naomi are members of the House of Fuji, a noble family.
ㅤ  

    Akira came directly, breaking all tradition. Was that it? Had he followed form—had he asked his mother to speak to his father to approach a go-between—would Chie have been more receptive?
ㅤ  
    He came on a winter’s eve. He pounded on the door while a cold rain beat on the shuttered veranda, so at first Chie thought him only the wind. The maid knew better. Chie heard her soft scuttling footsteps, the creak of the door. Then the maid brought a calling card to the drawing room, for Chie.
ㅤ  
    Chie was reluctant to go to her guest; perhaps she was feeling too cozy..."
              onChange={(e) => setPassage(e.target.value)}
              readOnly={isReadOnly}
            ></textarea>
          </div>
          <div className="textarea-column">
            <p><strong>Question</strong></p>
            <AutoFillQuestion question={question} handleQuestionChange={handleQuestionChange} />
            <textarea
              className={isReadOnly ? "textarea textarea-question response-textarea" : "textarea textarea-question"}
              id="question"
              value={parseLinesQuestion(question)}
              placeholder="I'm having trouble understanding what happens in this passage.
                           Can you please summarize the passage and then explain the different character dynamics that show up?"
              onChange={(e) => setQuestion(e.target.value)}
              readOnly={isReadOnly}
            ></textarea>
            <p><strong>Response</strong></p>
            <textarea
              className="textarea response-textarea"
              value={parseLinesResponse(response)}
              placeholder="Response will appear here"
              readOnly
            ></textarea>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
      
      <div className="textarea-container">
      <div className="textarea-column help">
        <h2>About:</h2>
        <p>ChatSAT is an AI assistant designed to help students
           with the SAT Reading section. The bot is designed to
           analyze SAT reading passages and questions, using the
           text to provide the best possible answer. However, keep
           in mind that <strong>the bot is still in Beta </strong>
           and thus may <em>occasionally</em> get a question wrong.
           In this case, you can <a href="https://danstestprep.com/free-consultation/" target="_blank">contact me</a> with
           the issue or email me directly at <em>dan@danstestprep.com</em> with the details. Have fun!
        </p>
        <h2>How to Use:</h2>
        <p>To use this Chatbot, simply paste in your SAT passage
           into the "Passage" text box. Afterwards, paste your question
           into the "Question" text box. Submit your query and the bot will
           automatically process it and respond to the best of its ability!
           <br />
           <br />- If you have a general question about SAT reading, you can just
           leave the "Passage" section blank.
           <br />- If you are asking about a question
           which relies on the answer to a previous question (i.e., "Which choice
           provides the best evidence for the answer to the previous question?"),
           paste both questions into the question box.</p>
      </div>
      <div className="textarea-column help">
        <h2>Features:</h2>
        <p>- Can answer any SAT reading passage-and-question pair <br/>
           - Can answer any general question about the SAT reading section <br/>
           - Cites textual evidence that is used to obtain the answer <br/>
           - Provides answer explanations for various questions
        </p>
        <h2>Limitations:</h2>
        <p>- Only does SAT reading (for now) <br/>
           - Does not yet remember previous conversation <br/>
           - Pre-filled questions/answers from the bot are vetted and are 100% correct.
             Custom questions are asked at your own risk and may occasionally be wrong.<br/>
           - More pre-filled tests and questions coming soon! <br/> <br/>
           I'm constantly working on improving ChatSAT.<br/>If you have any suggestions,
           please email me at <em>dan@danstestprep.com</em>.
        </p>
      </div>
    </div>
    </div>
  );
}

export default Chatbot;
