import React from 'react';
import passageList from './passageList';

class AutoFillPassage extends React.Component {
  render() {
    const {passage, handlePassageChange} = this.props;
    return (
      <div>
        <select id="selectPassage" onChange={handlePassageChange}>
          {passageList.map((passage, index) => (
            <option key={index} value={passage.name}>
              {passage.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default AutoFillPassage;