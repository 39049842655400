import React from 'react';
import questionList from './questionList';

class AutoFillQuestion extends React.Component {
  render() {
    const {question, handleQuestionChange} = this.props;
    return (
      <div>
        <select id="selectQuestion" onChange={handleQuestionChange}>
          {questionList.map((question, index) => (
            <option key={index} value={question.name}>
              {question.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default AutoFillQuestion;